'use strict';

/* global $ */
/* global config */

$(document).ready(function () {
    var search = decodeURIComponent(window.location.search).slice(1).split('&').map(function (item) { return item.split('='); }).reduce(function (o, k) { o[k[0]] = k[1]; return o; }, {});

    var highlights = [ 'RocketChat', 'SOGo', 'GitLab', 'Gogs', 'Wordpress', 'Nextcloud', 'ownCloud', 'Matomo', 'KanBoard', 'Taiga', 'Phabricator', 'Wallabag', 'Mailtrain', 'Wekan', 'NodeBB', 'Mattermost', 'Ghost', 'OpenVPN', 'Redash', 'Invoice Ninja', 'Minio', 'Mail Server' ];
    var container = $('#highlightContainer');
    var i = 0;

    // ensures we don't show the same twice in a row
    function getRandom(old, length) {
        var n = old;
        while (n === old) { n = Math.floor(Math.random() * length); }
        return n;
    }

    function showNext() {
        container.animate({ opacity: 0 }, {
            duration: 'slow',
            complete: function () {
                i = getRandom(i, highlights.length);
                $(this).text(highlights[i]);
                $(this).animate({ opacity: 1 }, 'slow');
            }
        });
    }

    container.text(highlights[i]);
    setInterval(showNext, 2000);

    function isScrolledIntoView(elem) {
        var docViewTop = $(window).scrollTop();
        var docViewBottom = docViewTop + $(window).height();

        var elemTop = $(elem).offset().top;
        var elemBottom = elemTop + $(elem).height();

        return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
    }

    function getCookie(cookieName) {
        var name = cookieName + '=';
        var allCookies = document.cookie.split(';');

        for (var i = 0; i < allCookies.length; i++) {
            var temp = allCookies[i].trim();
            if (temp.indexOf(name) == 0) return temp.substring(name.length, temp.length);
        }

        return null;
    }

    function setCookie(name, value) {
        var date = new Date();
        date.setTime(date.getTime()+ (30*24*60*60*1000));
        document.cookie = name + '=' + value + '; expires=' + date.toGMTString() + '; domain=cloudron.io; SameSite=Strict; Secure'; // this all overwrites any existing 'name'
    }

    // handle navbar shadow and video play
    var navbar = document.getElementById('navBar');
    var videoElements = document.getElementsByTagName('video');
    function handleScroll() {
        if (window.scrollY < 150) navbar.classList.remove('shadow')
        else navbar.classList.add('shadow')

        Object.keys(videoElements).forEach(function (k) {
            var elem = videoElements[k];
            if (isScrolledIntoView(elem)) elem.play();
            else elem.pause();
        });
    }
    handleScroll();
    document.addEventListener('scroll', handleScroll);

    $('.screenshots').slick({
        dots: true,
        lazyLoad: 'ondemand',
        infinite: true,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 2000,
    });

    if (search.refcode) setCookie('refcode', search.refcode);
    if (search.utm_source) setCookie('utm_source', search.utm_source);

    // calculate recent app updates
    var recentUpdatesContainer = document.getElementById('recentUpdatesContainer');

    function prettyDate(utc) {
        var date = new Date(utc), // this converts utc into browser timezone and not cloudron timezone!
            diff = (((new Date()).getTime() - date.getTime()) / 1000) + 30, // add 30seconds for clock skew
            day_diff = Math.floor(diff / 86400);

        if (isNaN(day_diff) || day_diff < 0)
            return 'just now';

        return day_diff === 0 && (
            diff < 60 && 'just now' ||
                diff < 120 && '1 minute ago' ||
                diff < 3600 && Math.floor( diff / 60 ) + ' minutes ago' ||
                diff < 7200 && '1 hour ago' ||
                diff < 86400 && Math.floor( diff / 3600 ) + ' hours ago') ||
            day_diff === 1 && 'Yesterday' ||
            day_diff < 7 && day_diff + ' days ago' ||
            day_diff < 31 && Math.ceil( day_diff / 7 ) + ' weeks ago' ||
            day_diff < 365 && Math.round( day_diff / 30 ) +  ' months ago' ||
                              Math.round( day_diff / 365 ) + ' years ago';
    }

    var req = new XMLHttpRequest();
    req.onreadystatechange = function () {
        if (req.readyState === XMLHttpRequest.DONE) {
            if (req.status !== 200) return console.error('Error fetching apps:', req.status, req.responseText);

            var apps;
            try {
                apps = JSON.parse(req.responseText).apps;
            } catch (e) {
                return console.error('App listing response is invalid JSON:', e);
            }

            var recentApps = apps.sort(function (a, b) {
                return a.creationDate > b.creationDate ? -1 : 1;
            }).slice(0, 6);

            var tmp = '';
            recentApps.forEach(function (app) {
                tmp += '<div class="app-update-item">';
                tmp += '<img src="' + app.iconUrl + '" />';
                tmp += '<h4>' + app.manifest.title + '</h4>';
                tmp += '<p>' + prettyDate(app.creationDate) + '</p>';
                tmp += '</div>';
            });

            recentUpdatesContainer.innerHTML = tmp;
        }
    };
    req.open('GET', config.apiServer + '/api/v1/apps', true);
    req.send();
});
